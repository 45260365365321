import React from "react"
import { ContactForm, Layout } from "../components"
import {
  Banner,
  PaddingWrapper,
  Content,
  already,
  Slider,
} from "@igloonet-web/shared-ui"
import styled from "styled-components"

import ProfileAdam from "../images/team/profile/adam.jpg"
import ProfileLukas from "../images/team/profile/lukas.jpg"
import ProfileKepi from "../images/team/profile/kepi.jpg"
import ProfileSobol from "../images/team/profile/sobol.jpg"
import { Helmet } from "react-helmet"

const GrayWrapper = styled.div`
  background: ${(props) => props.theme.color.gray200};
`
export default class EcommercePoradenstvi extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      activeTab: "1",
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  render() {
    const personSlider = [
      {
        id: 1,
        imageSrc: ProfileAdam,
        name: "Adam Šilhan",
        position: "10+ let zkušeností v online marketingu",
        text: "Google Certified Trainer oblasti Automation and Attribution, Exponea certifikace, hlavním záběrem je marketingová analytika, učí Webovou analytiku na Masarykově Univerzitě",
      },
      {
        id: 2,
        imageSrc: ProfileLukas,
        name: "Lukáš Havlík",
        position: "7 let zkušeností v online marketingu",
        text: "vede marketingový tým, garant pro sociální sítě, hlavním záběrem je komunikace a kampaně na sociálních sítích, mentoruje a školí klientské týmy",
      },
      {
        id: 3,
        imageSrc: ProfileKepi,
        name: "Ondra Kudlík",
        position: "20+ let zkušeností vývoj, hosting, devops",
        text: "řeší optimalizací větších eshopů a webových aplikací; dává velký důraz na bezpečnost a potenciální vendor lock; přednáší na Open Source konferencích",
      },
      {
        id: 4,
        imageSrc: ProfileSobol,
        name: "Jan Nobilis",
        position: "20+ let zkušeností vývoj, hosting, devops",
        text: "pomáhal klientům s přebíráním zastaralých individuálních eshopů i s přechodem od krabicových řešení; má přesah z čistě technické stránky do interních procesů eshopu a jejich napojení na backend; funguje jako překladatel mezi klienty a vývojáři",
      },
    ]

    return (
      <Layout>
        <Helmet>
          <title>E-commerce poradenství | igloonet</title>
          <meta
            name="description"
            content="Jste střední/větší e-shop a chcete hrát první ligu."
          />
        </Helmet>

        <Banner>
          <h1>E-commerce poradenství</h1>
          <p>Hrajte s námi v e-commerce první ligu</p>
        </Banner>

        <Content>
          <PaddingWrapper>
            <p>
              Za těch {already(2007)}, co jsou tučňáci tučňáky, jsme se u našich
              klientů setkali s lecčím – vstupem nových e-shopů na trh,
              expanzemi do zahraničí i&nbsp;globálními kampaněmi. Máme tak
              docela dobrou představu, kdy a proč to bude fungovat, kdy ne a kdy
              můžete letět tak blízko ke Slunci, že projekt shoří.
            </p>
            <p>
              Rádi vám předáme naše zkušenosti. Samotnou exekutivní práci
              (správa kampaní, stavění webů) děláme rádi dál, ale čím dál
              častěji pomáháme firmám na více strategické úrovni.
            </p>
          </PaddingWrapper>
        </Content>

        <GrayWrapper>
          <Content>
            <PaddingWrapper>
              <h2>Oblasti, které nás nejvíc baví a kde pomůžeme nejraději:</h2>
              <ul>
                <li>
                  <strong>mentoring a stavění interních týmů:</strong>{" "}
                  probereme, kdy dává smysl začít tým stavět, koho v něm budete
                  potřebovat, jak je správně najít a jak je dál rozvíjet;
                </li>
                <li>
                  <strong>připravenost e-shopu a infrastruktura:</strong>{" "}
                  nevíte, jestli váš e-shop na míru už není technologicky
                  zastaralý a nebude pro vás lepší jít do krabice, nebo máte
                  naopak pocit, že je vám krabice už těsná?
                </li>
                <li>
                  <strong>
                    marketingová strategie, optimalizace marketingových taktik:
                  </strong>{" "}
                  máte pocit, že jste narazili na strop, že nerostete tak
                  rychle, jak by se vám líbilo, snažíte se dohnat brand debt,
                  nebo jste se dostali do fáze, kdy chcete postupně zvyšovat
                  zisky?
                </li>
                <li>
                  <strong>
                    automatizace (primárně marketingová, ale i např. práce s
                    e-shopem, skladem, s objednávkami):
                  </strong>{" "}
                  netrvají vám některé činnosti zbytečně dlouho, když jdou dělat
                  strojově?
                </li>
                <li>
                  <strong>pohled zvenčí:</strong> e-shop často nejvíc potřebuje
                  pohled někoho, kdo danou situaci už párkrát řešil, a přitom
                  nemá tunelové vidění.
                </li>
              </ul>
            </PaddingWrapper>

            <h2>Nejčastěji pracujeme s e-shopy, ale poradíme i jinde</h2>
            <ul>
              <li>
                Pro T‑Mobile jsme připravili odbornou část tendru na on-line
                agenturu;
              </li>
              <li>
                Pro Study in Brno jsme vytvořili sérií školení na míru, dál
                mentorujeme interní tým.
              </li>
            </ul>
            <h2>Zkušenosti budujeme napříč oblastmi:</h2>
            <ol>
              <li>marketing,</li>
              <li>data,</li>
              <li>vývoj a technologie,</li>
              <li>infrastruktura.</li>
            </ol>
          </Content>
        </GrayWrapper>

        <Slider title={"Poznejte náš tým"} data={personSlider} />

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}
